@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'PlusJakartaSans-ExtraBold';
    src: url('./fonts/PlusJakartaSans-ExtraBold.woff2') format('woff2'),
        url('./fonts/PlusJakartaSans-ExtraBold.woff') format('woff'),
        url('./fonts/PlusJakartaSans-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'PlusJakartaSans-Bold';
    src: url('./fonts/PlusJakartaSans-Bold.woff2') format('woff2'),
        url('./fonts/PlusJakartaSans-Bold.woff') format('woff'),
        url('./fonts/PlusJakartaSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'PlusJakartaSans-Regular';
    src: url('./fonts/PlusJakartaSans-Regular.woff2') format('woff2'),
        url('./fonts/PlusJakartaSans-Regular.woff') format('woff'),
        url('./fonts/PlusJakartaSans-Regular.ttf') format('truetype');
}

:root {
    --green1: #518d64;
    --green2: #77c69b;
    --green3: #c1e4d1;
    --green4: #97dbcb;
    --green5: #e3f8f3;
    --blue1: #2f6786;
    --blue2: #77aac6;
    --blue3: #c4d4dd;
    --red1: #e6abab;
    --red2: #f4e2e2;
    --purple1: #bfa1d6;
    --purple2: #e0d4e8;
    --yellow1: #fae89a;
    --yellow2: #fff7d3;
    --info: #82bedf;
    --success: #35854e;
    --warning: #ceb750;
    --error: #cf6666;
}
